import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { addToArray, selectLastItem, selectLastValuesArray } from './latestValueSlice'
import { connect } from 'react-redux'
import socket from '../../app/socket'
import { Card } from 'react-bootstrap'
import moment from 'moment-timezone'

class LastValueChart extends Component {
  constructor (props) {
    super(props)
    this.options = {
      title: {
        text: 'Latest Values'
      },
      yAxis: {
        title: {
          text: 'Amperage'
        },
        min: 0,
        max: 20
      },
      xAxis: {
        title: {
          text: 'Time'
        },
        type: 'datetime',
        labels: {
          formatter: function () {
            return moment(this.value).format('h:mm:ss a')
          }
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      series: [{
        data: [],
        animation: false,
        name: 'Sensor'
      }]
    }
  }

  componentDidMount () {
    const {addToArray} = this.props
    socket.on(process.env.REACT_APP_LAST_VALUE_KEY, addToArray)
  }

  componentWillUnmount () {
    const {addToArray} = this.props
    socket.off(process.env.REACT_APP_LAST_VALUE_KEY, addToArray)
  }

  render () {
    this.options.series[0].data = this.props.lastValueArray
    return (
      <Card>
        <Card.Body>
          <Card.Text>
            <HighchartsReact
              highcharts={Highcharts}
              options={this.options}
              updateArgs={[true]}
            />
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  static propTypes = {
    lastValueArray: PropTypes.array.isRequired
  }
}

const mapState = (state) => ({
  lastValueArray: selectLastValuesArray(state)
})

const mapDispatch = {
  addToArray
}

export default connect(mapState, mapDispatch)(LastValueChart)