import React, { Component } from 'react'
import { connect } from 'react-redux'
import HistoricalChart from './HistoricalChart'
import { selectValues, fetchValues, updateStartDate, updateEndDate } from './historicalChartSlice'

import moment from 'moment-timezone'
import RangeSelector from './RangeSelector'
import { Col, Container, Row } from 'react-bootstrap'

class HistoricalValue extends Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <Container>
        <Row>
          <Col>
            <RangeSelector {...this.props} />
          </Col>
        </Row>
        <Row>
          <Col>
            <HistoricalChart {...this.props} />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapState = (state) => ({
  values: selectValues(state)
})

const mapDispatch = {
  fetchValues,
  updateStartDate,
  updateEndDate
}

export default connect(mapState, mapDispatch)(HistoricalValue)
