import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

const store = configureStore({
  reducer: rootReducer
})

if (module.hot) {
  module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer))
}

export default store
