import React from 'react'
import { Jumbotron, Container, Button, Card } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DeviceStatus from '../deviceStatus/DeviceStatus'
import Average from '../average/Average'
import LastValue from '../latestValue/LastValue'
import LastValueChart from '../latestValue/LastValueChart'

function Home () {
  return (
    <Container>
      <Row className='mb-4'>
        <Col className='text-center'>
          <DeviceStatus />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col md={{ span: 6 }}>
          <Average />
        </Col>
        <Col md={{ span: 6 }}>
          <LastValue />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col>
          <LastValueChart />
        </Col>
      </Row>
    </Container>
  )
}

export default Home
