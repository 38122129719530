import {createSlice} from '@reduxjs/toolkit'
import deviceStatusApi from './deviceStatusApi'

const deviceStatusSlice = createSlice({
  name: 'deviceStatus',
  initialState: "OFF",
  reducers: {
    updateStatus: (state, action) => action.payload
  }
})

export default deviceStatusSlice.reducer

export const {updateStatus} = deviceStatusSlice.actions

export const selectDeviceStatus = (state) => state.deviceStatus

export const fetchDeviceStatus = () => async (dispatch, getState) => {
  const result = await deviceStatusApi()
  console.log('deviceStatusApi is:' , result)
  dispatch(updateStatus(result))
}