import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {selectDeviceStatus} from './deviceStatusSlice'
import {updateStatus, fetchDeviceStatus} from './deviceStatusSlice'
import socket from '../../app/socket'

class DeviceStatus extends Component {
  render(){
    const {status} = this.props
    return (
      <h2 className={`display-5 justify-content-center`}>
        Device Status:
        <span className={`text-nowrap ${this.color(status)} text-white ml-1 pl-1 pr-2`}>
          {status}
        </span>
      </h2>
    )
  }

  componentDidMount () {
    socket.on(process.env.REACT_APP_ON_OFF_STATUS_KEY, this.props.updateStatus)
    this.props.fetchDeviceStatus()
  }

  componentWillUnmount () {
    socket.off(process.env.ON_OFF_STATUS_KEY, this.props.updateStatus)
  }

  color(status) {
    if(status === "ON"){
      return "bg-primary"
    } else if(status === "OFF"){
      return "bg-dark"
    } else {
      return "bg-danger"
    }
  }

  static propTypes = {
    status: PropTypes.string.isRequired
  }
}

const mapState = (state) => ({
  status: selectDeviceStatus(state)
})

const mapDispatch = {
  updateStatus,
  fetchDeviceStatus
}

export default connect(mapState,mapDispatch)(DeviceStatus)
