import React, { Component } from 'react'
import {connect} from 'react-redux'
import {selectLastItem, selectLastValuesArray} from './latestValueSlice'
import {addLastItem} from './latestValueSlice'
import socket from '../../app/socket'
import LastValueChart from './LastValueChart'
import { Card } from 'react-bootstrap'

class LastValue extends Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
    const {addLastItem} = this.props
    socket.on(process.env.REACT_APP_LAST_VALUE_KEY, addLastItem)
  }

  componentWillUnmount () {
    const {addLastItem} = this.props
    socket.off(process.env.REACT_APP_LAST_VALUE_KEY, addLastItem)
  }

  render () {
    const {lastValue} = this.props
    return (
      <Card>
        <Card.Body>
          <Card.Title>Last Value</Card.Title>
          <Card.Text>
            <h1 className={`display-5`}>
              {lastValue ? parseFloat(lastValue).toFixed(2) : 0} Amps
            </h1>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }
}

const mapState = (state) => ({
  lastValue: selectLastItem(state)
})

const mapDispatch = {
  addLastItem
}

export default connect(mapState, mapDispatch)(LastValue)

