import React, { useEffect } from 'react'
import logo from './logo.svg'
import { Counter } from './features/counter/Counter'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import Average from './features/average/Average'
import LastValue from './features/latestValue/LastValue'
import HistoricalValue from './features/historicalValue/HistoricalValue'
import { Container, Row, Col } from 'react-bootstrap'
import Navigation from './features/navigation/Navigation'
import Home from './features/home/Home'
import DeviceStatus from './features/deviceStatus/DeviceStatus'

function App () {
  return (
    <Container>
      <Navigation />
      <br />
      <Switch>
        <Route path='/counter' component={Counter} />
        <Route path='/average' component={Average} />
        <Route path='/lastValue' component={LastValue} />
        <Route path='/historicalValue' component={HistoricalValue} />
        <Route path='/status' component={DeviceStatus} />
        <Route path='/' component={Home} />
      </Switch>
    </Container>
  )
}

export default App
