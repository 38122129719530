import React, { Component } from 'react'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone'
import 'react-dates/initialize';
import PropTypes from 'prop-types'
import { fetchValues, updateEndDate, updateStartDate } from './historicalChartSlice'

class RangeSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      startDate: moment().subtract(1, 'days'),
      endDate: moment().endOf('day'),
      focusedInput: null
    }
    this.datesChanged = this.datesChanged.bind(this)
  }

  componentDidMount () {
    this.props.updateStartDate(this.state.startDate.unix())
    this.props.updateEndDate(this.state.endDate.unix())
    this.props.fetchValues()
  }

  render() {
    return (
      <div>
        <span className={'mr-2 align-middle'}
              style={{fontSize: 25}}>
          Time Period: </span>
        <DateRangePicker
          isOutsideRange={() => false}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="1" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="2" // PropTypes.string.isRequired,
          onDatesChange={this.datesChanged} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        />
      </div>
    )
  }
  datesChanged({startDate, endDate}) {
    console.log('in datesChange with: ', startDate.format(), endDate.format())
    this.setState({ startDate, endDate })
    if (startDate) this.props.updateStartDate(startDate.unix())
    if (endDate) this.props.updateEndDate(endDate.unix())
    if (startDate && endDate && endDate.unix() > startDate.unix()) this.props.fetchValues()
  }

  static propTypes = {
    fetchValues: PropTypes.func.isRequired,
    updateStartDate: PropTypes.func.isRequired,
    updateEndDate: PropTypes.func.isRequired
  }
}

export default RangeSelector