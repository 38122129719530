import historicalChartApi from './historicalChartApi'
const { createSlice } = require('@reduxjs/toolkit')
const moment = require('moment-timezone')

const historicalChartSlice = createSlice({
  name: 'historicalChart',
  initialState: {
    startDate: '',
    endDate: '',
    values: []
  },
  reducers: {
    updateStartDate: (state, action) => { state.startDate = action.payload },
    updateEndDate: (state, action) => { state.endDate = action.payload },
    updateValues: (state, action) => { state.values = action.payload }
  }
})

export default historicalChartSlice.reducer

export const { updateStartDate, updateEndDate, updateValues } = historicalChartSlice.actions

export const selectValues = (state) => {
  return state.historicalChart.values.map(item => {
    return [moment(item.day).valueOf(), parseFloat(item.amperage_average)]
  })
}
export const selectStartDate = (state) => state.historicalChart.startDate
export const selectEndDate = (state) => state.historicalChart.endDate

export const fetchValues = () => async (dispatch, getState) => {
  const state = getState()
  const startDate = selectStartDate(state)
  const endDate = selectEndDate(state)
  if (startDate && endDate && endDate > startDate) {}
  const result = await historicalChartApi(startDate, endDate)
  dispatch(updateValues(result))
}
