import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap'

class AverageBox extends Component {
  constructor (props) {
    super(props);
  }

  render() {
    const {value, ttl, title} = this.props
    return (
      <Card>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            <h1 className={`display-5`}>
              {value ? parseFloat(value).toFixed(2) : '0'} Amps
            </h1>
          </Card.Text>
        </Card.Body>
        {ttl ? <Card.Footer className="text-muted">Value resets in {ttl} seconds</Card.Footer> : ''}
      </Card>
    )
  }

  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.number.isRequired,
    ttl: PropTypes.number
  }

  static defaultProps = {
    title: 'Average',
    ttl: undefined
  }
}

export default AverageBox