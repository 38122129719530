import { combineReducers } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import averageReducer from '../features/average/averageSlice'
import lastValueReducer from '../features/latestValue/latestValueSlice'
import historicalCharReducer from '../features/historicalValue/historicalChartSlice'
import deviceStatusReducer from '../features/deviceStatus/deviceStatusSlice'

const rootReducer = combineReducers({
  counter: counterReducer,
  average: averageReducer,
  lastValue: lastValueReducer,
  historicalChart: historicalCharReducer,
  deviceStatus: deviceStatusReducer
})

export default rootReducer
