import {createSlice} from '@reduxjs/toolkit'

const lastValueSlice = createSlice({
  name: 'lastValue',
  initialState: {
    array: [],
    lastItem: 0
  },
  reducers: {
    addLastItem: (state, action) => {
      const value = action.payload.split(':')[1]
      state.lastItem = parseFloat(value)
    },
    addToArray: (state, action) => {
      const ts = action.payload.split(':')[0]
      const value = action.payload.split(':')[1]
      state.lastItem = value
      state.array.push([ts, value])
      state.array = state.array.slice(-60)
    }
  }
})

export default lastValueSlice.reducer

export const {addLastItem, addToArray} = lastValueSlice.actions

export const selectLastItem = (state) => {
  return state.lastValue.lastItem
}

export const selectLastValuesArray = (state) => {
  return state.lastValue.array.map(item => {
    return [parseInt(item[0]), parseFloat(item[1])]
  })
}