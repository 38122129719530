import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"

function Navigation() {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">Sensor Data</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link className='nav-link' to="/">Dashboard</Link>
          <Link className='nav-link' to="/historicalValue">Historical Values</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation