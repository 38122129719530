import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost';

Boost(Highcharts);

// function getData(n) {
//   var arr = [],
//     i,
//     a,
//     b,
//     c,
//     spike;
//   for (i = 0; i < n; i = i + 1) {
//     if (i % 100 === 0) {
//       a = 2 * Math.random();
//     }
//     if (i % 1000 === 0) {
//       b = 2 * Math.random();
//     }
//     if (i % 10000 === 0) {
//       c = 2 * Math.random();
//     }
//     if (i % 50000 === 0) {
//       spike = 10;
//     } else {
//       spike = 0;
//     }
//     arr.push([
//       i,
//       2 * Math.sin(i / 100) + a + b + c + spike + Math.random()
//     ]);
//   }
//   return arr;
// }
// var n = 500000,
//     data = getData(n);


class HistoricalChart extends Component {
  constructor (props) {
    super(props)
    this.options = {
      title: {
        text: 'Historical Chart'
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      chart: {
        zoomType: 'x'
      },
      xAxis: {
        title: {
          text: 'Time'
        },
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Amperage'
        },
        min: 0,
        max: 20
      },
      series: [{
        name: 'Sensor',
        data: [],
        animation: false
      }]
    }
  }

  render () {
    // this.options.series[0].data = data
    this.options.series[0].data = this.props.values
    
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.options}
        updateArgs={[true]}
      />
    )
  }

  static propTypes = {
    values: PropTypes.array
  }
}

export default HistoricalChart