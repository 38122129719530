import { createSlice } from '@reduxjs/toolkit'

const averageSlice = createSlice({
  name: 'average',
  initialState: {
    fixedTimeWindow: { value: 0, ttl: 0},
    slidingTimeWindow: 0,
    lastRecordsWindow: 0
  },
  reducers: {
    updateFixedTimeWindow: (state, action) => {
      const {value, ttl} = JSON.parse(action.payload)
      state.fixedTimeWindow.value = value
      state.fixedTimeWindow.ttl = ttl
    },
    updateSlidingTimeWindow: (state, action) => {
      state.slidingTimeWindow = action.payload
    },
    updateLastRecordsWindow: (state, action) => {
      state.lastRecordsWindow = action.payload
    }
  }
})

export default averageSlice.reducer

export const {
  updateFixedTimeWindow,
  updateSlidingTimeWindow,
  updateLastRecordsWindow
} = averageSlice.actions