import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AverageBox from './AverageBox'
import { connect } from 'react-redux'
import {
  updateFixedTimeWindow,
  updateLastRecordsWindow,
  updateSlidingTimeWindow
} from './averageSlice'
import socket from '../../app/socket'
import LastValue from '../latestValue/LastValue'

class Average extends Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    // socket.on('lastRecordsWindow', this.props.updateLastRecordsWindow)
    // socket.on('fixedTimeWindow', this.props.updateFixedTimeWindow)
    socket.on(process.env.REACT_APP_SLIDING_WINDOW_KEY, this.props.updateSlidingTimeWindow)
  }

  componentWillUnmount () {
    // socket.off('lastRecordsWindow', this.props.updateLastRecordsWindow)
    // socket.off('fixedTimeWindow', this.props.updateFixedTimeWindow)
    socket.off(process.env.REACT_APP_SLIDING_WINDOW_KEY, this.props.updateSlidingTimeWindow)
  }

  foo () {
    console.log('in foo')
  }

  render () {
    const { average } = this.props
    return (
      <div>
        {/*<AverageBox*/}
        {/*  {...average.fixedTimeWindow}*/}
        {/*  title='Fixed Time Window'*/}
        {/*/>*/}
        <AverageBox
          value={average.slidingTimeWindow}
          title='60 second average'
        />
        {/*<AverageBox*/}
        {/*  value={average.lastRecordsWindow}*/}
        {/*  title='Last N Records Window'*/}
        {/*/>*/}
      </div>
    )
  }
}

const mapState = (state) => ({
  average: state.average
})

const mapDispatch = {
  updateFixedTimeWindow,
  updateLastRecordsWindow,
  updateSlidingTimeWindow
}

export default connect(mapState, mapDispatch)(Average)
